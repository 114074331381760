import { MouseEvent, useState } from "react";
import { Button, Menu } from "@kaltura/ds-react-components";
import { Calendar24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { AddToCalendarOptionsType, ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { AddToCalendarMenuItems } from "@kaltura/mediaspace-shared-entry";

export interface MailMenuProps {
    entryId: string;
    categoryId?: number;
}

export function MailMenu({ entryId, categoryId }: MailMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        sendButtonAnalytics("Add to calendar - Session card - Menu open", ButtonClickAnalyticsType.MENU);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const calendarMenuItemClick = (calendarType: AddToCalendarOptionsType) => {
        const buttonType =
            calendarType === AddToCalendarOptionsType.EMAIL
                ? ButtonClickAnalyticsType.SEND
                : ButtonClickAnalyticsType.DOWNLOAD;
        sendButtonAnalytics(`Add to calendar - Session card - ${calendarType}`, buttonType);
        handleMenuClose();
    };

    return (
        <div className="kms-ds-event-list-item-menu">
            <Button
                id={`EventListItemCalendarToggleBtn-${entryId}`}
                variant={"borderless"}
                aria-haspopup={true}
                aria-expanded={open}
                aria-controls={open ? `EventListItemCalendarToggleMenu-${entryId}` : undefined}
                onClick={handleButtonClick}
                className={`kms-ds-event-list-item-button-calendar`}
                size={"large"}
                color={"secondary"}
            >
                <Calendar24Icon />
                {translate("Add To Calendar")}
            </Button>
            <Menu
                id={`EventListItemCalendarToggleMenu-${entryId}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                aria-labelledby={`EventListItemCalendarToggleBtn-${entryId}`}
            >
                <AddToCalendarMenuItems
                    entryId={entryId}
                    categoryId={categoryId}
                    onItemClicked={calendarMenuItemClick}
                />
            </Menu>
        </div>
    );
}

export default MailMenu;
